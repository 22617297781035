<template>

	<layout :include_header="true">

		<template v-slot:header-center>

			<main_nav />

		</template>

		<template v-slot:header-end>

			<support_link />

		</template>

		<template v-slot:main-body>

			<div class="w-100 h-100 position-relative overflow-hidden">

				<div class="w-100 h-100 d-flex">

					<div class="d-flex flex-column w-100 h-100">

						<div class="d-flex pe-2 w-100 align-items-center justify-content-between border-bottom position-relative">
						
							<div class="d-flex align-items-center">
								<div class="p-3 px-4 rounded-0 d-flex align-items-center justify-content-center fw-bold border-end" style="width: 270px;">
									API keys
								</div>
								
							</div>
						</div>

						<div class="h-100 w-100 overflow-auto">
						
							<div class="container py-4">

								<div class="row justify-content-center">

									<div class="col-12 col-md-10 col-lg-6 col-xxl-5">

										<div class="card mb-3">

											<div v-if="loading">

												<loader />

											</div>

											<div v-else>

												<div class="bg-white p-4 border-bottom position-sticky">
													<form_control 
														name="keyword"
														type="text"
														placeholder="Start typing to filter sites..."
														group_class="'mb-0'"
														v-model="keyword"
													/>
												</div>

												<div v-for="( site, key_key ) in filtered_keys" :key="'api-key-' + site.id">
													
													<div v-if="site.settings['url']" class="card-body p-4" :class="key_key > 0 ? 'border-top' : ''">
														
														<p class="mb-4 h6 d-flex align-items-center justify-content-between">
															{{ site.settings['url'] }}

															<router-link v-if="!site.deleted_at && site.workspace && !site.workspace.deleted_at" :to="{ name: 'site.show', params: { 'workspace_id': site.workspace_id, 'site_id': site.id } }" class="btn btn-outline-secondary btn-sm">
																Open Site
															</router-link>
														</p>

														<div class="d-flex align-items-center bg-light p-2 border rounded">
															
															<span class="me-2">API Key:</span>

															<div class="bg-white rounded p-1 border flex-grow-1 overflow-auto text-center">
																{{ site.api_key }}
															</div>

														</div>

													</div>

												</div>

											</div>

										</div>

									</div>

								</div>

							</div>

						</div>

					</div>

				</div>

			</div>

		</template>

	</layout>

</template>

<script>
import { mapGetters } from 'vuex';
import layout from '@/components/layout'
import main_nav from '@/components/main_nav'
import support_link from '@/components/support_link'
import form_control from '@/components/form_control'
import loader from '@/components/loader'
import api_service from '@/services/api_service'

export default {
	name: 'api-keys',

	components: {
		layout,
		main_nav,
		support_link,
		form_control,
		loader
	},

	data() {
		return {
			loading: false,
			error: {},
			success: null,
			keys: [],
			keyword: null
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		filtered_keys()
		{
			if ( !this.keyword ) {
				return this.keys
			}
			return this.keys.filter(( row ) => {
				return row.settings['url'].includes( this.keyword )
			})
		}

	},

	mounted()
	{
		this.get_api_keys()
	},

	methods: {
		reset_error()
		{
			this.error = {}

			this.success = null
		},

		async get_api_keys()
		{
			this.loading = true 

			this.reset_error()

			api_service.keys().then(( response ) => {
				this.$store.commit( 'user/SET_USER', response.data )

				this.keys = response.data

				this.success = true
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},
	}
}
</script>

<style>

</style>
